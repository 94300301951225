@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_mixins.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_print.scss";
@import "../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_responsive-utilities.scss";

@media print {
    html,
    body,
    div {
        width: 100%;
        height: auto;
        overflow: visible !important;
    }
    .topbar,
    nav,
    aside,
    #top,
    #header,
    #dektop-nav,
    #footer,
    #hero,
    #cookie-notice {
        display: none;
    }
}
